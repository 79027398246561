import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Filip Heden" />
    <h1>Welcome!</h1>
    <p>This site is under construction.</p>
    <p>
      <Link to="https://gitlab.com/Hedeen/">gitlab</Link> <br />
      <Link to="https://github.com/PerFilip/">github</Link> <br />
      <Link to="https://www.linkedin.com/in/filip-hedén-823682171/">linkedin</Link> <br />
    </p>
  </Layout>
)
//Link to="site/resume/">resume</Link> <br />  /TODO add some time
export default IndexPage
